<template>
  <userFull />
</template>

<script>
// @ is an alias to /src
import userFull from "@/components/layout/userFull.vue";

export default {
  name: "UserPage",
  components: {
    userFull,
  },
};
</script>
<style scoped lang="scss">
</style>
