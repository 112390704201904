<template>
  <Index />
</template>

<script>
// @ is an alias to /src
import Index from "@/components/layout/Index.vue";

export default {
  name: "Home",
  components: {
    Index,
  },
};
</script>

<style scoped>
</style>
